import { useContext, useState, FormEvent } from "react";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import { config } from '../config/config';

const Ramen = require("./assets/ramen-midj-ill.png") as string;

const URL = config.url;

const LoginUser: React.FC = () => {
    const [email, setEmail ] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();

    function isError(err: any): err is Error {
        return err instanceof Error;
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    
    const loginUser = async () => {
        try {
            const response = await fetch(`${URL}/users/login`, {
                method: 'POST',
                credentials: "include",
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });
        
            if (response.ok) {
                const result = await response.json();
                // const userEmail = result.email;
                // const userId = result.userId;
                const passwordCheck = result.passwordCheck;
        
                if (
                    // userId !== undefined && 
                    // userEmail !== undefined && 
                    passwordCheck !== false
                ) {
                    setEmail('');
                    setPassword('');
                    setUser(result);
                    navigate('/ramen', { state: { token: result.token } });
                } else {
                    handleFailedLogin();
                }
            } else if (response.status === 404) {
                handleFailedLogin();
            } else {
                handleFailedLogin();
            }
        } catch (err) {
            if (isError(err)) {
                console.log("An unknown error occurred:", err);
                handleFailedLogin();
            }
        }
    };

    const handleFailedLogin = () => {
        setErrorMessage("Login failed, password or email incorrect");
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await loginUser();
    };

    return (
        <div className="form-container fade-page flex space-around">
            <div className="login-page-image" style={{width: "500px"}}>
                <img src={Ramen} alt="ramen" style={{ width: "500px"}} />
            </div>
            <div style={{width: "500px", position: "relative"}}>
                <form method="post" onSubmit={handleSubmit} encType="multipart/form-data" className="login-form">
                    <label className="labels">
                        Email
                        <input 
                            type="text" 
                            name="email" 
                            placeholder="email"
                            onChange={e => setEmail(e.target.value)} />
                    </label>
                    <label className="labels">
                        Password
                            <input 
                                type={showPassword ? "text" : "password"} // Toggle password type
                                name="password" 
                                placeholder="password"
                                onChange={e => setPassword(e.target.value)}
                            
                            />
                            <button 
                                type="button" 
                                onClick={togglePasswordVisibility} 
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "242px",
                                    transform: "translateY(-50%)",
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                    padding: 0,
                                }}
                            >
                                {showPassword ? "🙈" : "👁️"} 
                            </button>
                    </label>
                    <div className="error-message">{errorMessage}</div>
                    <input 
                        type="submit" 
                        value="Submit" 
                        className="primary-submit-button cursor-pointer" 
                        />
                </form>
            </div>
        </div>
    );
};

export default LoginUser;
