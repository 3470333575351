import { useState, useEffect, useContext,  } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faEye, faTrash, faBookmark } from '@fortawesome/free-solid-svg-icons'
import { UserContext } from "../UserContext";
import { config } from '../config/config';

const URL = config.url;


interface User {
    id: string;
    _id: string;
    name: string;
    surname: string;
    email: string;
    password: string;
    imageUrl: string;
    publicId: string;
    public_id: string;
    yourFavourites: boolean;
}

const UserFavourites: React.FC<User> = (yourFavourites) => {
    const [user, setUser] = useState<User>({ 
        id:'', 
        name: '', 
        surname: '', 
        email: '', 
        password: '', 
        imageUrl: '', 
        publicId: '', 
        _id:'', 
        public_id:'',
        yourFavourites: false, 
    });
    const [ramens, setRamens] = useState<any[]>([]);
    const [isFavourite, setFavourite] = useState(false);
    const userContext = useContext(UserContext);
    const userData = userContext?.user;
    const navigate = useNavigate();

    const params = useParams();
    const id = params.id;
    console.log(id)

    console.log("!!!!! -> is there a usserID here?",user.id)

    const isRamenOwner = (ramenUser: string) => {
        return userData?.userId === ramenUser;
    };

    useEffect(() => {
        getFavouriteRamens();
        },
    []);

    const getFavouriteRamens = async () => {
        try {
            const response = await fetch(`${URL}/users/user/${id}/favourites`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setRamens(data);
            } else {
                console.error('Failed to fetch user ramens');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const deleteRamen = async (id: string, public_id: string, user_id: string) => {
        await fetch(`${URL}/ramen/delete/${id}/${public_id}/user/${user_id}`, {
            method: 'DELETE',
            credentials: 'include'
            }).then((response) => {            
            if (response.status === 200) {
                console.log("Ramen deleted");
                setRamens(prevRamen => prevRamen.filter(ramen => ramen._id !== id));
                } else {
                    console.log("Ramen not deleted");
                }
            });
        };

    const viewRamen = async (id:string) => {
        navigate(`/ramen/show/${id}`);
    };

    const updateRamen = (id:string) => {
        navigate(`/ramen/update/${id}`);
    };
    
    const favouriteRamen = async (ramen_id: string, user_id: string) => {
        await fetch(`${URL}/favourites/favourite/ramen/${ramen_id}/user/${user_id}`, {
            method:'POST',
            credentials: 'include'
        }).then((response) => {            
            if (response.status === 200) {
                console.log("Ramen added to favourites");
                setFavourite(true);
                } else {
                    console.log("Ramen not added to favourites");
                }
            });
    };

    const unfavouriteRamen = async (ramen_id: string, user_id: string) => {
        await fetch(`${URL}/favourites/unfavourite/ramen/${ramen_id}/user/${user_id}`, {
            method:'DELETE',
            credentials: 'include'
        }).then((response) => {            
            if (response.status === 200) {
                console.log("Ramen removed from favourites");
                setFavourite(true);
                } else {
                    console.log("Ramen not removed from favourites");
                }
            });
    };

    return (
        <div>
            { yourFavourites.yourFavourites ? 
                <h1>Your favourite ramens</h1>
                : 
                <h1>Favourite ramens</h1>
            }
            { ramens.length > 0 ? (
            <div className="profile-card-area">
                {ramens.map((ramen) => {
                    const owner = isRamenOwner(ramen.user);
                    return (
                        <div id={ramen._id} key={ramen._id} className="ramen-card" >
                            <div className="card-image-container">
                                <img src={ramen.imageUrl} alt="" style={{width: 400}} />
                            </div>
                            <div className="card-text-area">
                                <h4>{ramen.title}</h4>
                                <h2>{ramen.ingredients}</h2>
                            {user ? (
                                <div className="card-button-area">
                                    <div className="show-button button" onClick={() => viewRamen(ramen._id)} >
                                        <FontAwesomeIcon icon={faEye} className="eye"/>
                                    </div>
                                    { owner ? (
                                        <div className="delete-update-favourite-container">   
                                            <div className="update-button button" onClick={() => updateRamen(ramen._id)} >
                                                <FontAwesomeIcon icon={faPenToSquare} className="update"/>
                                            </div>
                                            <div className="delete-button button" onClick={() => deleteRamen(ramen._id, ramen.public_id, ramen.user)} id={ramen.id}>
                                                <FontAwesomeIcon icon={faTrash} className="delete"/>
                                            </div>
                                            <div className="favourite-button button" onClick={() => favouriteRamen(ramen._id, user.id)} id={ramen.id}>
                                            { isFavourite ? (
                                                <FontAwesomeIcon icon={faBookmark}  className="favourite-icon" onClick={() => unfavouriteRamen(ramen._id, ramen.user)}/>
                                            ) : (
                                                <FontAwesomeIcon icon={faBookmark}  className=""/>
                                            )}
                                            </div>
                                        </div>
                                        ) : (
                                        <div>
                                        </div>
                                        )
                                    }
                                </div>
                                    ) : (
                                        <div className="card-button-area">
                                            <div className="show-button button" onClick={() => viewRamen(ramen._id)} >
                                            <FontAwesomeIcon icon={faEye} className="eye"/>
                                            </div>
                                        </div>
                                    )}
                                </div>
                        </div>
                        );
                })}
            </div>
            ) : ( 
                yourFavourites.yourFavourites ?
                    <h2>You have no favourites</h2>
                    : 
                    <h2>This user has no Favourites</h2>
                )
            }
        </div>
    )
}

export default UserFavourites;