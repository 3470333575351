import { useNavigate, useParams } from 'react-router-dom';
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { config } from '../config/config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UserContext } from '../UserContext';
import Dropdown from "./dropdown";
import Spinner from '../assets/Spinner.gif';

interface Oil {
    title: string;
    ingredients: string;
    description: string;
    imageUrl: string;
    publicId: string;
    id: string;
    tags: string;
}

interface Ingredient {
    ingredient: string;
    calories: string; 
    imageUrl: string;  
    publicId: string;
    user: string;
}

interface Tag {
    tag: string;
}

const URL = config.url;

const UpdateOilForm: React.FC<Oil & Ingredient & Tag> = () => {
    const [title, setTitle ] = useState('');
    const [ingredients, setIngredients ] = useState('');
    const [ingredient, setIngredient] = useState([]);
    const [tags, setTags ] = useState('');
    const [tag, setTag] = useState([]); 
    const [description, setDescription ] = useState('');
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [publicId, setPublicId] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const userContext = useContext(UserContext);
    const user = userContext?.user;
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const id = params.id;
        fetch(`${URL}/oil/show/${id}`, {
            method: 'GET',
            credentials: "include"
            }).then((response) => response.json())
            .then((data) => {
                setTitle(data.title);
                setIngredients(data.ingredients);
                setDescription(data.description);
                setImageUrl(data.imageUrl);
                setPublicId(data.public_id);
            })
            .catch((err) => {
                console.log(err.message);
            });
        },
        [params.id]);

    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await fetch(`${URL}/ingredients`, {
                method: 'GET',
                credentials: 'include',
            });
            const data = await response.json();
            setIngredient(
                data.map((ingredient: Ingredient) => ({ 
                    value: ingredient.ingredient, 
                    label: ingredient.ingredient, 
                    })
                )
            );
            } catch (error) {
                console.log((error as Error).message, ":error message");
            }
        }

        fetchData();
    }, []);

    const cloudinaryUsername = process.env.REACT_APP_CLOUDINARY_USERNAME;
    const cloudinaryPreset = process.env.REACT_APP_CLOUDINARY_PRESET;
    const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudinaryUsername}/image/upload`;
    
    const uploadImage = async (files: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = files.target.files?.[0];
    
        if (selectedFile) {
            setIsUploading(true);
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("upload_preset", `${cloudinaryPreset}`);

        try {
            const response = await fetch(uploadUrl, {
            method: "POST",
            body: formData,
            });
    
            if (response.ok) {
                const data = await response.json();
                setImageUrl(data.secure_url);
                setPublicId(data.public_id);
                } else {
                    console.log("Image upload failed");
                }
            } catch (error) {
                console.error("Error uploading image:", error);
            } finally {
                setIsUploading(false);
            }
        }
    };


    const updateOil = async ({title, ingredients, description, imageUrl, publicId, id, tags}: Oil) => {

        await fetch(`${URL}/oil/update/${id}`, {
            method: 'PUT',
            credentials: "include",
            body: JSON.stringify({
                title: title,
                ingredients: ingredients,
                description: description,
                tags: tags,
                imageUrl: imageUrl,
                publicId: publicId,
                id: id
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => { 
                response.json();
            })
            .then(() => {
                setTitle('');
                setIngredients('');
                setDescription('');
                setImageUrl('');
                setPublicId('');
                setTags('');
            })
            .catch((err) => {
            console.log(err.message , ":error message");
        });
    }

    const handleSubmit = () => {
        const id = params.id
        const oil: Oil = { 
            title,
            ingredients,
            description, 
            imageUrl, 
            publicId, 
            id: id || '',
            tags
        }
        updateOil(oil);
        navigate(`/oil/show/${id}`);
        
    };
    

    return (
        <div className="form-container">
            <div className="form-image-container">
                {imageUrl && <img className="new-ramen-image" alt="preview" src={imageUrl} onLoad={() => setIsImageLoaded(true)} />}
            </div>
            <form method="post" onSubmit={handleSubmit} encType="multipart/form-data">
                <label className="labels">
                    Title
                    <input 
                        type="text" 
                        name="title" 
                        placeholder={title}
                        onChange={e => setTitle(e.target.value)} />
                </label>
                <label className="labels">
                    Ingredients       
                </label>         
                    <Dropdown
                        isSearchable
                        isMulti
                        placeHolder="Select..."
                        options={ingredient}
                        onChange={(selectedIngredients: any[]) => {
                            const selectedIngredientValues = selectedIngredients.map(ingredient => ingredient.value);
                            setIngredients(selectedIngredientValues.join(', '));
                        }}
                    />
                <label className="labels">
                    Tags      
                </label>         
                    <Dropdown
                        isSearchable
                        isMulti
                        placeHolder="Select..."
                        options={tag}
                        onChange={(selectedTags: any[]) => {
                            const selectedTagValues = selectedTags.map(tag => tag.value);
                            setTags(selectedTagValues.join(', '));
                        }}
                    />
                <label className="labels">
                    Description
                    <ReactQuill
                        theme="snow"
                        placeholder={description}
                        value={description}
                        onChange={setDescription} />
                </label>
                <label className="labels">
                    Image
                    {isUploading ? (
                    <div className="flex-no-space">  
                    <div className="spinner-form">
                        <input type="file" name="ramen" onChange={uploadImage}/>
                    </div>
                    <div className="spinner-form-container">
                        <img src={Spinner} alt="spinner" className="spinner"/>
                    </div>
                </div>
                ) : (
                    <input type="file" name="ramen" onChange={uploadImage}/>
                )}
                </label>
                <label className="labels hidden">
                    imageUrl
                    <textarea 
                        name="imageUrl" 
                        value={imageUrl}
                        placeholder={imageUrl}
                        onChange={e => setImageUrl(e.target.value)} />
                </label>
                <label className="labels hidden">
                    publicId
                    <textarea 
                        name="publicId" 
                        value={publicId}
                        placeholder={publicId}
                        onChange={e => setPublicId(e.target.value)} />
                </label>
                <input type="submit" value="Submit" className="primary-submit-button" />
            </form>
        </div>
    )
};

export default UpdateOilForm;
