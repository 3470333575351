import {PrimaryButton} from "../components/buttons";

const Ramen = require("./assets/ramen-midj-ill.png") as string;

const Home = () => {
    return (
        <div className="container fade-page">
            <div className="hero-banner">
                <div className="hero-img mobile"> 
                    <img src={Ramen} alt="ramen" />
                </div>
                <div className="hero-blurb">
                    <div className="hero-txt">
                        <h1 style={{
                            color: "#ED3D1E"
                            }}>ラーメン 狂った</h1>
                        <hr style={{
                            background: "#272624",
                            border: "1px solid",
                            height:"1px"
                        }}
                        />
                    </div>
                    <div className="hero-txt-area">
                        <h1>A RAMEN COOKBOOK</h1>
                        <p>Made for ramen lovers, this cookbook will help you in your pursuit of becoming your own fully fledged ramen chef.</p>
                    </div>
                    <div className="home-button">
                        <PrimaryButton />
                    </div>
                </div>
                <div className="hero-img desktop"> 
                    <img src={Ramen} alt="ramen" style={{ width: "500px"}} />
                </div>
            </div>
        </div>
    );
}

export default Home;