import { useState, useEffect, ChangeEvent, FormEvent, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { config } from '../config/config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Dropdown from "./dropdown";
import { UserContext } from '../UserContext';
import Spinner from '../assets/Spinner.gif';

interface Oil {
    title: string;
    ingredients: string;
    description: string;
    tags: string;
    imageUrl: string;
    publicId: string;
    user: string;
}

interface Ingredient {
    ingredient: string;
}

interface Tag {
    tag: string;
}

interface User {
    userId: string;
}

const URL = config.url;

const AddOil: React.FC<Oil | Ingredient | User | Tag> = () => {
    const [title, setTitle ] = useState('');
    const [ingredients, setIngredients ] = useState('');
    const [ingredient, setIngredient] = useState([]);
    const [tags, setTags ] = useState('');
    const [tag, setTag] = useState([]); 
    const [description, setDescription ] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [publicId, setPublicId] = useState('');
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const userContext = useContext(UserContext);
    const user = userContext?.user;
    
    const navigate = useNavigate();

    const cloudinaryUsername = process.env.REACT_APP_CLOUDINARY_USERNAME;
    const cloudinaryPreset = process.env.REACT_APP_CLOUDINARY_PRESET;

    const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudinaryUsername}/image/upload`;

    const uploadImage = async (files: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = files.target.files?.[0];
    
        if (selectedFile) {
            setIsUploading(true);
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("upload_preset", `${cloudinaryPreset}`);
        
            try {
            const response = await fetch(uploadUrl, {
                method: "POST",
                body: formData,
            });
        
            if (response.ok) {
                const data = await response.json();
                setImageUrl(data.secure_url);
                setPublicId(data.public_id);
            } else {
                console.log("Image upload failed");
            }
            } catch (error) {
            console.error("Error uploading image:", error);
            } finally {
                setIsUploading(false);
            }
        }
    };

    const AddOil = async ( {title, ingredients, description, imageUrl, publicId, user, tags}: Oil) => {

        await fetch(`${URL}/oil/add`, {
        method: 'POST',
        credentials: "include",
        body: JSON.stringify({
            title: title,
            ingredients: ingredients,
            description: description,
            tags: tags,
            imageUrl: imageUrl,
            publicId: publicId,
            user: user
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
        })
        .then((response) => { 
            console.log(response.json());
        })
        .then(() => {
        setTitle('');
        setIngredients('');
        setDescription('');
        setTags('');
        // setUser('');
        })
        .catch((err) => {
        console.log(err.message , ":error message");
    });
    navigate('/oil');
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const oil: Oil = {
            title, 
            ingredients, 
            description,
            tags, 
            imageUrl, 
            publicId,
            user: user?.userId || ''
        }
        AddOil(oil);
    };
    
    useEffect(() => {
        fetchIgredients();
        fetchTags();
    }, []);

    const fetchIgredients = async () => {
        try {
            const response = await fetch(`${URL}/ingredients/ingredients`, {
                method: 'GET',
                credentials: 'include',
            });
            const data = await response.json();
            setIngredient(
                data.map((ingredient: Ingredient) => ({ 
                    value: ingredient.ingredient, 
                    label: ingredient.ingredient, 
                    })
                )
            );
            } catch (error) {
                console.log((error as Error).message, ":error message");
            }
        }

    const fetchTags = async () => {
        try {
            const response = await fetch(`${URL}/tags/tags`, {
                method: 'GET',
                credentials: 'include',
            });
            const data = await response.json();
            setTag(
                data.map((tag: Tag) => ({ 
                    value: tag.tag, 
                    label: tag.tag, 
                    })
                )
            );
            } catch (error) {
                console.log((error as Error).message, ":error message");
            }
        }

    const allFieldsEntered = title && ingredient && tags && imageUrl && publicId;

    return (
    <div className="form-container">
        <div className="form-image-container">
            {imageUrl && <img src={imageUrl} alt="preview" onLoad={() => setIsImageLoaded(true)} className="uploaded-image" />}
        </div>
        <form method="post" onSubmit={handleSubmit} encType="multipart/form-data">
            <label className="labels">
                Title
                <input 
                    type="text" 
                    name="title" 
                    placeholder="Type here..."
                    onChange={e => setTitle(e.target.value)} />
            </label>
            <label className="labels">
                Ingredients       
            </label>         
                <Dropdown
                    isSearchable
                    isMulti
                    placeHolder="Select..."
                    options={ingredient}
                    onChange={(selectedIngredients: any[]) => {
                        const selectedIngredientValues = selectedIngredients.map(ingredient => ingredient.value);
                        setIngredients(selectedIngredientValues.join(', '));
                    }}
                />
            <label className="labels">
                Tags      
            </label>         
                <Dropdown
                    isSearchable
                    isMulti
                    placeHolder="Select..."
                    options={tag}
                    onChange={(selectedTags: any[]) => {
                        const selectedTagValues = selectedTags.map(tag => tag.value);
                        setTags(selectedTagValues.join(', '));
                    }}
                />
            <label className="labels">
                Description
                <ReactQuill
                    theme="snow"
                    placeholder="Type here..."
                    onChange={setDescription} />
            </label>
            <label className="labels">
                Image
                {isUploading ? (
                    <div className="flex-no-space">  
                    <div className="spinner-form">
                        <input type="file" name="ramen" onChange={uploadImage}/>
                    </div>
                    <div className="spinner-form-container">
                        <img src={Spinner} alt="spinner" className="spinner"/>
                    </div>
                </div>
                ) : (
                    <input type="file" name="ramen" onChange={uploadImage}/>
                )}
            </label>
            <label className="labels hidden">
                imageUrl
                <textarea 
                    name="imageUrl" 
                    value={imageUrl}
                    onChange={e => setImageUrl(e.target.value)} />
            </label>
            <label className="labels hidden">
                publicId
                <textarea 
                    name="publicId" 
                    value={publicId}
                    onChange={e => setPublicId(e.target.value)} />
            </label>
            {isImageLoaded && allFieldsEntered ?(
                        <input
                            type="submit"
                            value="Add oil"
                            className="primary-submit-button"
                            disabled={!isImageLoaded}
                        />
                    ) : (
                        <input
                            type="submit"
                            value="Fill in form first"
                            className="primary-submit-button-grey"
                            disabled={!isImageLoaded}
                        />
                    )}
        </form>
    </div>
    )
};

export default AddOil;
