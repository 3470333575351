import UpdateDashiForm from "../components/updateDashiForm";

const UpdateDashi = () => {

    return (
        <div className="">
            <div className="fade-page">
                <div className="page-title">
                    <h1>Update Tare</h1>
                </div>
                <UpdateDashiForm />
            </div>
        </div>
    );
}

export default UpdateDashi;