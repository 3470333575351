
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBowlFood } from '@fortawesome/free-solid-svg-icons'
import UserCard from '../components/userCard';
import { useEffect, useState } from 'react';
import { config } from '../config/config';
const URL = config.url;

interface Users {
    username: string;
    imageUrl: string;
    _id: string;
    isFollowing: boolean;
}


const Following= () => {
    const [following, setFollowing] = useState<Users[]>([]);
    useEffect(() => {
        const fetchFollowing = async () => {
            fetch(`${URL}/follows/following`, {
                method: 'GET',
                credentials: 'include'
                })
            .then((response) => response.json())
            .then((data) => {
                const updatedData = data.map((user: Users) => ({ ...user, isFollowing: true }));
                setFollowing(updatedData);
                console.log(updatedData);
            })
            .catch((error) =>
            console.log("Error fetching users", error)
            )};
            fetchFollowing();
    }, []);

    console.log("following",following)

    return (
        <div className="ramen-list-body fade-page">
            <div className="">
                <div className="page-title">
                    <h1 style={{
                                color: "#ED3D1E"
                                }}>いただきます</h1>
                    <hr style={{
                            background: "#272624",
                            border: "1px solid",
                            height:"1px"
                        }}/>
                    <h1>Following <FontAwesomeIcon icon={faBowlFood} className="ramen-bowl"/></h1>
                </div>
                { following.length > 0 ? (
                <div>
                    {following.map((following, index) => (
                        <UserCard key={index} {...following} />
                    ))
                    }
                </div>
                ) : ( 
                    <div style={{margin: "auto 0", textAlign: "center"}}><h2>You aren't following anyone</h2></div>)}
            </div>
        </div>
    );
}

export default Following;