import { useEffect, useState } from "react";
import { config } from '../config/config';
import { SecondaryButton } from './buttons';
import { useNavigate } from 'react-router-dom';

const URL = config.url;

interface SearchResult {
    name:string;
    _id: string;
}


const Search: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [resultsVisible, setResultsVisible] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const performSearch = async () => {
        if (searchTerm.trim() !== '') {
            try {
            const response = await fetch(`${URL}/search/search?query=${encodeURIComponent(searchTerm)}`, {
                method: "GET",
                credentials: "include"
            });

            const data = await response.json();

            setSearchResults(data);
            } catch (error) {
            console.error('Error:', error);
            }
        } else {
            setSearchResults([]);
        }
        };
        performSearch();
    }, [searchTerm]);
    
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setResultsVisible(true);
    };    

    const navigateUser = async (_id: string) => {
        navigate(`/user/show/${_id}`);
        setResultsVisible(false);
        setSearchTerm('');
    }

    return (
    <div>
        <div className="nav-search">
            <form action="">
                <div className="search-form">
                    <input
                        type="text"
                        id="search"
                        className="search-input" 
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search for user"
                    />
                </div>
            </form>
            {resultsVisible && (
            <div>
                {searchTerm.length > 0 && (
                <div className="nav-results-container">
                    <div>
                        <ul>
                        {searchResults.length === 0 ? (
                            <li>No results found</li>
                            ) : (
                            searchResults.map(result => (
                                <div className="flex space-between nav-search-results">
                                    <div>
                                    {result.name}
                                    </div>
                                    <div>
                                    <SecondaryButton 
                                        buttonText="See Profile"
                                        onClick={() => navigateUser(result._id)}
                                    />
                                    </div>
                                </div>
                            ))
                            )}
                        </ul>
                    </div>
                </div>
                )}
            </div>)}
        </div>
    </div>
    )
}

export default Search;