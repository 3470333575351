import FeedCard from '../components/feedCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBowlFood } from '@fortawesome/free-solid-svg-icons'
import { useContext, useEffect, useState } from 'react';
import { config } from '../config/config';
import { UserContext, UserContextProps } from '../UserContext';

const URL = config.url;

const FollowingFeed: React.FC = () => {
    const [users, setUsers] = useState([]);
    const [ramens, setRamens] = useState([]);
    const { user, setUser } = useContext<UserContextProps>(UserContext);
    
    useEffect(() => {
            fetchFollowedUsers();
        }, []);

    const fetchFollowedUsers = () => {
        fetch(`${URL}/feed/ramen-feed/${user?.userId}`, {
            method: "GET",
            credentials: "include"
        })
        .then((response) => response.json())
        .then((data) => {
            setRamens(data);
        })
        .catch((err) => {
            console.log(err.message);
        });
    }

    return (
        <div className="ramen-list-body fade-page">
            <div className="">
                <div className="page-title">
                    <h1 style={{
                                color: "#ED3D1E"
                                }}>あなたのために</h1>
                    <hr style={{
                            background: "#272624",
                            border: "1px solid",
                            height:"1px"
                        }}/>
                    <h1>For You <FontAwesomeIcon icon={faBowlFood} className="ramen-bowl"/></h1>
                </div>
                <div>
                    <FeedCard />
                </div>
            </div>
        </div>
    );
}

export default FollowingFeed;