import AddOilForm from "../components/addOilForm";

const NewOil = () => {
    return (
        <div className="">
            <div className="fade-page">
                <div className="page-title">
                    <h1>Add an Oil</h1>
                </div>
                <AddOilForm />
            </div>
        </div>
    );
}

export default NewOil;