
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBowlFood } from '@fortawesome/free-solid-svg-icons'
import UserCard from '../components/userCard';
import { useEffect, useState } from 'react';
import { config } from '../config/config';
const URL = config.url;

interface Users {
    follower: any;
    following: any;
    user: any;
    username: string;
    imageUrl: string;
    _id: string;
}

const Followers: React.FC<Users>  = () => {
    const [followers, setFollowers] = useState<Users[]>([]);
    const [following, setFollowing] = useState<Users[]>([]);

    useEffect(() => {
        const fetchFollowers = async () => {
            fetch(`${URL}/follows/followers`, {
                method: 'GET',
                credentials: 'include'
                })
            .then((response) => response.json())
            .then((data) => {
                setFollowers(data);
                console.log("Followers array",data)
            })
            .catch((error) =>
            console.log("Error fetching users", error)
            )};
            fetchFollowers();

            const fetchFollowing = async () => {
                fetch(`${URL}/follows/following`, {
                    method: 'GET',
                    credentials: 'include'
                    })
                .then((response) => response.json())
                .then((data) => {    
                    const updatedData = data.map((user: Users) => ({ ...user, isFollowing: true }));
                    console.log("Following array",updatedData)
                    setFollowing(updatedData);
                })
                .catch((error) =>
                console.log("Error fetching users", error)
                )};
            fetchFollowing();
    }, []);

    // console.log("Followers", followers)
    // console.log("Following", following)

    return (
        <div className="ramen-list-body fade-page">
            <div className="">
                <div className="page-title">
                    <h1 style={{
                                color: "#ED3D1E"
                                }}>いただきます</h1>
                    <hr style={{
                            background: "#272624",
                            border: "1px solid",
                            height:"1px"
                        }}/>
                    <h1>Your Followers <FontAwesomeIcon icon={faBowlFood} className="ramen-bowl"/></h1>
                </div>
                { followers.length > 0 ? (
                <div>
                    {followers.map((follower, index) => {
                        const isFollowing = following.some(following => following._id === follower._id);
                        return <UserCard isFollowing={isFollowing} key={index} {...follower} />;
                    })}
                </div> 
                ) : ( 
                <div style={{margin: "auto 0", textAlign: "center"}}><h2>You have no followers</h2></div>)}
            </div>
        </div>
    );
}

export default Followers;