import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBowlFood } from '@fortawesome/free-solid-svg-icons'
import DashiList from '../components/dashiList'


const Dashi = () => {
    return (
        <div className="ramen-list-body fade-page">
            <div className="">
                <div className="page-title">
                    <h1 style={{
                                color: "#ED3D1E"
                                }}>だし</h1>
                    <hr style={{
                            background: "#272624",
                            border: "1px solid",
                            height:"1px"
                        }}/>
                    <h1>All Dashi <FontAwesomeIcon icon={faBowlFood} className="ramen-bowl"/></h1>
                </div>
                <DashiList />
            </div>
        </div>
    );
}

export default Dashi;