import UpdateTareForm from "../components/updateTareForm";

const UpdateTare = () => {

    return (
        <div className="">
            <div className="fade-page">
                <div className="page-title">
                    <h1>Update Tare</h1>
                </div>
                <UpdateTareForm />
            </div>
        </div>
    );
}

export default UpdateTare;