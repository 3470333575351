import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { config } from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'

const URL = config.url;

interface Tare {
    id: string;
    ingredient: string;
    calories: number;
    imageUrl: string;
    public_id: string;
    user: string;
}

const TareList: React.FC<Tare> = () => {
    const [tare, setTare] = useState<any[]>([]);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const isTareOwner = (tareUser: string) => {
        return user?.userId === tareUser;
    };
    
    useEffect(() => {
        fetch(`${URL}/tare`, {
            method: 'GET',
            credentials: 'include',
            })
            .then((response) => response.json())
            .then((data) => {
                setTare(data);
            })
            .catch((err) => {
                console.log(err.message);
            });  
        }, []);

    const deleteTare = async (id:string, public_id: string, user_id:string) => {
        await fetch(`${URL}/tare/delete/${id}/${public_id}/user/${user_id}`, {
            method: 'DELETE',
            credentials: 'include',
            }).then((response) => {            
            if (response.status === 200) {
                    console.log("Tare deleted");
                    setTare(prevTare => prevTare.filter(tare => tare._id !== id));
                } else {
                    console.log("Tare not deleted");
                }
            });
        };

    const viewTare = async (id:string) => {
        console.log("this is id", id);
        navigate(`/tare/show/${id}`);
    };

    const updateTare = (id:string) => {
        navigate(`/tare/update/${id}`);
    };

    return (
        <div className="">
            <div className="card-area">
                {tare.map((tare) => {
                    const owner = isTareOwner(tare.user);

                return (
                    <div key={tare._id} id={tare._id} className="ramen-card" >
                        <div className="card-image-container">
                            <img src={tare.imageUrl} alt="" style={{width: 400}} />
                        </div>
                        <div className="card-text-area">
                            <h4>{tare.title}</h4>
                            <h2>{tare.ingredients}</h2>
                        {user ? (
                            <div className="card-button-area">
                                <div className="show-button button" onClick={() => viewTare(tare._id)} >
                                    <FontAwesomeIcon icon={faEye} className="eye"/>
                                </div>
                                { owner ? (
                                    <div className="delete-update-container">
                                        <div className="update-button button" onClick={() => updateTare(tare._id)} >
                                            <FontAwesomeIcon icon={faPenToSquare} className="update"/>
                                        </div>
                                        <div className="delete-button button" onClick={() => deleteTare(tare._id, tare.public_id, tare.user)} id={tare.id}>
                                            <FontAwesomeIcon icon={faTrash} className="delete"/>
                                        </div>
                                    </div>
                                ):(
                                    <div>
                                    </div>
                                )}
                            </div>
                                ) : (
                                    <div className="card-button-area">
                                        <div className="show-button button" onClick={() => viewTare(tare._id)} >
                                        <FontAwesomeIcon icon={faEye} className="eye"/>
                                        </div>
                                    </div>
                                )}
                            </div>
                    </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TareList;