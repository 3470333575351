import { useState, useEffect, useContext,  } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../config/config';
import { UserContext, UserContextProps } from '../UserContext';
import UserRamens from "../components/userRamens";
import UserIngredients from "../components/userIngredients";
import UserFavourites from "../components/userFavourites";
import { TertiaryButton } from "../components/buttons";


const URL = config.url;

interface User {
    id: string;
    _id: string;
    name: string;
    surname: string;
    email: string;
    imageUrl: string;
    header_image_url:string;
    header_public_id:string;
    publicId: string;
    public_id: string;
    uuid: string;
}

const ShowUser: React.FC<User> = () => {
    const navigate = useNavigate();
    const [selectedMenuItem, setSelectedMenuItem] = useState('Ramens');
    const [user, setUser] = useState<User>({ 
        id:'', 
        name: '', 
        surname: '', 
        email: '', 
        imageUrl: '', 
        header_image_url: '',
        header_public_id: '',
        publicId: '', 
        _id:'', 
        public_id:'' ,
        uuid: ''
    });
    const [followingCount, setFollowingCount] = useState(0);
    const [followersCount, setFollowersCount] = useState(0);
    const [yourRamens, setYourRamens] = useState(true);
    const [yourIngredients, setYourIngredients] = useState(true);
    const [yourFavourites, setYourFavourites] = useState(true);
    const params = useParams();
    const userContext = useContext(UserContext);

    const handleMenuItemClick = (item: any) => {
        setSelectedMenuItem(item);
    };

    useEffect(() => {
        getUser();
        fetchFollowersCount();
        fetchFollowingCount();
        checkRamensIngredientsFavourites();
        },
    []);

    const uuid = params.id;

    const getUser = () => {
        fetch(`${URL}/users/user/show/${uuid}`, {
            method: 'GET',
            credentials: "include",
            })
            .then((response) => response.json())
            .then((data) => {
                setUser(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const followUser = async (_id: string) => { 
        console.log(_id)
        try {
            const response = await fetch(`${URL}/follows/follow/${uuid}`, {
                method: 'POST',
                credentials: 'include'
                }
            );
        
            const data = await response.json();
            console.log(data.message);
        } catch (error) {
            console.error('Error:', error);
        }
        };

    const fetchFollowersCount = async () => {
        try {
            const response = await fetch(`${URL}/follows/user/${uuid}/follower/count`, {
                    method: 'GET',
                    credentials: 'include'
                }
            );
            const data = await response.json();
            setFollowersCount(data.count);
        } catch (error) {
            console.error('Error fetching followers count:', error);
        }
    };

    const fetchFollowingCount = async () => {
        try {
            const response = await fetch(`${URL}/follows/user/${uuid}/following/count`, {
            method: 'GET',
            credentials: 'include'
                }
            );
            const data = await response.json();
            // console.log("following:",data)
            setFollowingCount(data.count);
        } catch (error) {
            console.error('Error fetching followers count:', error);
        }
    };

    const navigateFollowers = async (uuid: string) => {
        navigate(`/user/followers/${uuid}`)

    }

    const navigateFollowing = async (uuid: string) => {
        navigate(`/user/following/${uuid}`)
    }


    const checkRamensIngredientsFavourites = () => {

        if (userContext.user?.userId !== uuid) {
            setYourRamens(false)
            setYourIngredients(false)
            setYourFavourites(false)
        } else {
            console.log("--- false")
        }
    }


    return (
        <div className="show-user-container">
            <div className="">
                <div className="profile-header">
                    <div className="profile-header-image">
                        <img src={user.header_image_url} alt="header" className="header-image" />
                    </div> 
                    <div className="show-user-image-container">  
                        <img src={user.imageUrl} alt="" className="show-user-image"/>
                    </div>
                    <div className="profile-user-name">
                        <h1>{user.name} {user.surname}</h1>
                        <div className="profile-follow-buttons-area">
                        <TertiaryButton
                                buttonText="Follow"
                                onClick={() => followUser(user.uuid)}
                            />
                            <TertiaryButton
                                buttonText={`${followingCount} Following`}
                                onClick={() => navigateFollowing(user._id)}
                            />
                            <TertiaryButton
                                buttonText={`${followersCount} Followers`}
                                onClick={() => navigateFollowers(user._id)}
                            />
                            
                        </div>
                    </div>
                    <div className="profile-sections">
                        <ul className="profile-menu">
                            { yourRamens ?
                                <li className="profile-menu-button" onClick={() => handleMenuItemClick('Ramens')}>Your Ramens</li>
                                :
                                <li className="profile-menu-button" onClick={() => handleMenuItemClick('Ramens')}>Ramens</li>
                            }

                            { yourIngredients ?
                            <li className="profile-menu-button" onClick={() => handleMenuItemClick('Ingredients')}>Your Ingredients</li>
                            :
                            <li className="profile-menu-button" onClick={() => handleMenuItemClick('Ingredients')}>Ingredients</li>                 
                            }  


                            { yourFavourites ?
                            <li className="profile-menu-button" onClick={() => handleMenuItemClick('Favourites')}>Your Favourites</li>
                            :
                            <li className="profile-menu-button" onClick={() => handleMenuItemClick('Favourites')}>Favourites</li>                 
                            }    
                        </ul>
                    </div>
                </div>
                <div className="profile-content-container">  
                    {selectedMenuItem === 'Ramens' && <UserRamens yourRamens={yourRamens} />}
                    {selectedMenuItem === 'Ingredients' && <UserIngredients yourIngredients={yourIngredients} />}
                    {selectedMenuItem === 'Favourites' && <UserFavourites yourFavourites={yourFavourites} />}
                </div>
                
            </div>
        </div>
    );
}

export default ShowUser;