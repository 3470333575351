import { useState, useEffect, useContext,  } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { UserContext } from "../UserContext";
import { config } from '../config/config';

const URL = config.url;


interface User {
    id: string;
    _id: string;
    name: string;
    surname: string;
    email: string;
    password: string;
    imageUrl: string;
    publicId: string;
    public_id: string;
    yourIngredients: boolean;
}

const UserIngredients: React.FC<User> = (yourIngredients) => {
    const [user, setUser] = useState<User>({ 
        id:'', 
        name: '', 
        surname: '', 
        email: '', 
        password: '', 
        imageUrl: '', 
        publicId: '', 
        _id:'', 
        public_id:'' ,
        yourIngredients: false,
    });
    const [ingredients, setIngredients] = useState<any[]>([]);
    const userContext = useContext(UserContext);
    const userData = userContext?.user;
    const navigate = useNavigate();

    const params = useParams();
    const id = params.id;

    const isIngredientOwner = (ramenUser: string) => {
        return userData?.userId === ramenUser;
    };

    useEffect(() => {
        getIngredients();
        },
    []);

    const getIngredients = () => {
        fetch(`${URL}/users/user/${id}/ingredients`, {
            method: 'GET',
            credentials: 'include'
        })
        .then((response) => response.json())
        .then((data) => {
            setIngredients(data);
        })
        .catch((err) => {
            console.log(err.message);
        })
    }

    const deleteIngredient = async (id:string, public_id: string, user_id:string, user:string) => {
        await fetch(`${URL}/ingredient/delete/${id}/${public_id}/user/${user_id}`, {
            method: 'DELETE',
            credentials: 'include',
            }).then((response) => {            
            if (response.status === 200) {
                    console.log("Ingredient deleted");
                    setIngredients(prevIngredient => prevIngredient.filter(ingredient => ingredient._id !== id));
                } else {
                    console.log("Ingredient not deleted");
                }
            });
        };

    const viewIngredient = async (id:string) => {
        console.log("this is id", id);
        navigate(`/ingredient/show/${id}`);
    };

    const updateIngredient = (id:string) => {
        navigate(`/ingredient/update/${id}`);
    };




    return (
        <div> 
            {yourIngredients ?            
            <h1>Your Ingredients</h1>
            :
            <h1>Ingredients</h1>
        }
            { ingredients.length > 0 ? (
            <div className="profile-card-area">
                {ingredients.map((ingredient) => {
                const owner = isIngredientOwner(ingredient.user);
                return (
                    <div key={ingredient._id} id={ingredient._id} className="ramen-card" >
                        <div className="card-image-container">
                            <img src={ingredient.imageUrl} alt="" style={{width: 400}} />
                        </div>
                        <div className="card-text-area">
                            <h4>{ingredient.ingredient}</h4>
                            <h2>{ingredient.calories}</h2>
                        {user ? (
                            <div className="card-button-area">
                                <div className="show-button button" onClick={() => viewIngredient(ingredient._id)} >
                                    <FontAwesomeIcon icon={faEye} className="eye"/>
                                </div>
                                { owner ? (
                                    <div className="delete-update-container">
                                        <div className="update-button button" onClick={() => updateIngredient(ingredient._id)} >
                                            <FontAwesomeIcon icon={faPenToSquare} className="update"/>
                                        </div>
                                        <div className="delete-button button" onClick={() => deleteIngredient(ingredient._id, ingredient.public_id, sessionStorage.getItem('id')!, ingredient.user)} id={ingredient.id}>
                                            <FontAwesomeIcon icon={faTrash} className="delete"/>
                                        </div>
                                    </div>
                                ):(
                                    <div>
                                    </div>
                                )}
                            </div>
                                ) : (
                            <div className="card-button-area">
                                <div className="show-button button" onClick={() => viewIngredient(ingredient._id)} >
                                    <FontAwesomeIcon icon={faEye} className="eye"/>
                                </div>
                            </div>
                                )}
                        </div>
                    </div>
                        );
                    })}   
                </div> 
                ) : ( 
                    yourIngredients.yourIngredients ?
                    <h2>You have no ramens</h2>
                    : 
                    <h2>This user has no ramens</h2>
                )}
            </div>
    )
}

export default UserIngredients;