import UpdateNoodleForm from "../components/updateNoodleForm";

const UpdateOil = () => {

    return (
        <div className="">
            <div className="fade-page">
                <div className="page-title">
                    <h1>Update Oil</h1>
                </div>
                <UpdateNoodleForm />
            </div>
        </div>
    );
}

export default UpdateOil;