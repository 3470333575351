import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { config } from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleLeft, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'

const URL = config.url;

const ShowOil: React.FC = () => {
    const [oil, setOil] = useState<any>({
        title: '',
        ingredients: '',
        description: '',
        tags: '',
        imageUrl: '',
        public_id: '',
        });
    const userContext = useContext(UserContext);
    const user = userContext?.user;
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const id = params.id;

        fetch(`${URL}/oil/show/${id}`, {
            method: 'GET',
            credentials: "include",
            }).then((response) => response.json())
            .then((data) => {
                setOil(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
        },
        [params.id]);
        
        const deleteOil = async (id: string, public_id: string, user_id: string) => {
            await fetch(`${URL}/oil/delete/${id}/${public_id}/user/${user_id}`, {
                method: 'DELETE',
                credentials: 'include'
                }).then((response) => {            
                if (response.status === 200) {
                    console.log("Oil deleted");
                    navigate('/oil');
                    } else {
                        console.log("Oil not deleted");
                    }
                });

            };

        const allOils = () => {
            navigate('/oil');
        }

        const updateOil = (id:string) => {
            navigate(`/oil/update/${id}`);
        };

        const isOilOwner = (oilUser: string) => {
            return user?.userId === oilUser;
        };

        const owner = isOilOwner(oil.user);
        
    return (
        <div className="show-ramen-container fade-page">
            <div className="show-ramen">
                <div className="ramen-recipe" >
                    <div className="show-page-img-ing">   
                        <div className="show-image-container">  
                            <img src={oil.imageUrl} style={{width: 400}} alt="" />
                        </div>
                        <div className="show-ramen-ing">
                            <h1>Ingredients</h1>
                            <h2 className="">{oil.ingredients}</h2>
                            <h1>tags</h1>
                            <h2 className="">{oil.tags}</h2>
                        </div>
                    </div>
                    <div className="show-page-description">
                    <h1>{oil.title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: oil.description}} />
                        {user ? (
                        <div className="card-button-area-show flex">
                            <div className="show-button button" onClick={() => allOils()} ><FontAwesomeIcon icon={faCircleLeft} className="back"/> Back to list</div>
                            { owner ? (
                                    <div className="delete-update-container">   
                                        <div className="update-button button" onClick={() => updateOil(oil._id)} >
                                            <FontAwesomeIcon icon={faPenToSquare} className="update"/>Update
                                        </div>
                                        <div className="delete-button button" onClick={() => deleteOil(oil._id, oil.public_id, oil.user)} id={oil.id}>
                                            <FontAwesomeIcon icon={faTrash} className="delete"/>Delete
                                        </div>
                                    </div>
                                    ) : (
                                    <div>
                                    </div>
                                    )
                                }
                        </div>
                        ) : (
                        <div className="card-button-area-show">
                            <div className="show-button button" onClick={() => allOils()} ><FontAwesomeIcon icon={faCircleLeft} className="ramen-bowl"/> Back to list</div>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShowOil;