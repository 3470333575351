import { useState, useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { config } from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faBookmark } from '@fortawesome/free-solid-svg-icons'

const URL = config.url;

const FeedCard: React.FC= () => {
    const [feeds, setFeed] = useState<any[]>([]);
    // const [feedUser, setFeedUser] = useState<any[]>([]);
    const [isFavourite, setFavourite] = useState(false);
    const [favoriteFeedIds, setFavoriteFeedIds] =  useState<string[]>([]);
    const userContext = useContext(UserContext);
    const user = userContext?.user;

    // const navigate = useNavigate();

    // const isFeedOwner = (feedUser: string) => {
    //     return user?.userId === feedUser;
    // };
    
    useEffect(() => {
        console.log("user -> ",user)
        fetch(`${URL}/feed/${user?.userId}`)
            .then((response) => response.json())
            .then((data) => {
                setFeed(data.feedObject);
                console.log("full object",data)
            })
            .catch((err) => {
                console.log(err.message);
            });
        }, []);


    const viewRamen = async (id:string) => {
        navigate(`/ramen/show/${id}`);
    };

    const favouriteRamen = async (ramen_id: string, user_id: string) => {
        await fetch(`${URL}/favourites/favourite/ramen/${ramen_id}/user/${user_id}`, {
            method:'POST',
            credentials: 'include'
        }).then((response) => {            
            if (response.status === 200) {
                console.log("Ramen added to favourites");
                setFavourite(true);
                setFavoriteFeedIds((prevIds) =>
                        prevIds.includes(ramen_id)
                            ? prevIds.filter((id) => id !== ramen_id)
                            : [...prevIds, ramen_id]
                    );
                } else {
                    console.log("Ramen not added to favourites");
                    setFavourite(false);
                }
            });
    };

    const fetchFavourites = async () => {
        if (user) {
            fetch(`${URL}/users/user/${user.userId}/favourites/bookmarks`,  {
                method:'GET',
                credentials: 'include'
            })
                .then((response) => response.json())
                .then((favourites) => {
                    const favoriteIds = favourites.map((favorite: any) => favorite.ramen_id);
                    setFavoriteFeedIds(favoriteIds);
                    })
                .catch((err) => {
                    console.error(err);
                    console.log("failure");
                });
        }
    };

    return (
        <div className="">
    <div className="card-area-feed">
        {feeds.map((feed) => {
            const { user } = feed; // Destructure user for clarity
            return user.ramens.map((ramen: { _id: string; imageUrl: string; title: string; ingredients: string; }) => ( 
                <div id={ramen._id} key={ramen._id} className="feed-card">
                    <div className="user-profile flex">
                        <img src={user.imageUrl} alt={user.username} style={{ width: 50, height: 50, borderRadius: '50%', paddingRight: "5px" }} />
                        <h4>{user.username}</h4>
                    </div>
                    <div className="card-image-container">
                        <img src={ramen.imageUrl} alt={ramen.title} style={{ width: 500 }} />
                    </div>
                    <div className="card-text-area-feed">
                        <h2>{ramen.title}</h2>
                        <p>{ramen.ingredients}</p>
                    </div>
                    <div className="feed-card-actions">
                        <FontAwesomeIcon icon={faEye} className="eye show-button" style={{ paddingRight: "10px" }} onClick={() => viewRamen(ramen._id)}/>
                        <FontAwesomeIcon icon={faBookmark}  className="favourite-button" onClick={() => favouriteRamen(ramen._id, user?.userId)} />
                    </div>
                </div>
                ));
            })}
        </div>
    </div>
    );
};

export default FeedCard;