import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { config } from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'

const URL = config.url;

interface Noodle {
    id: string;
    ingredient: string;
    calories: number;
    imageUrl: string;
    public_id: string;
    user: string;
}

const NoodleList: React.FC<Noodle> = () => {
    const [noodle, setNoodle] = useState<any[]>([]);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const isNoodleOwner = (noodleUser: string) => {
        return user?.userId === noodleUser;
    };
    
    useEffect(() => {
        fetch(`${URL}/noodle`, {
            method: 'GET',
            credentials: 'include',
            })
            .then((response) => response.json())
            .then((data) => {
                setNoodle(data);
            })
            .catch((err) => {
                console.log(err.message);
            });  
        }, []);

    const deleteNoodle = async (id:string, public_id: string, user_id:string) => {
        await fetch(`${URL}/noodle/delete/${id}/${public_id}/user/${user_id}`, {
            method: 'DELETE',
            credentials: 'include',
            }).then((response) => {            
            if (response.status === 200) {
                    console.log("Noodle deleted");
                    setNoodle(prevNoodle => prevNoodle.filter(noodle => noodle._id !== id));
                } else {
                    console.log("Noodle not deleted");
                }
            });
        };

    const viewNoodle = async (id:string) => {
        console.log("this is id", id);
        navigate(`/noodle/show/${id}`);
    };

    const updateNoodle = (id:string) => {
        navigate(`/noodle/update/${id}`);
    };

    return (
        <div className="">
            <div className="card-area">
                {noodle.map((noodle) => {
                    const owner = isNoodleOwner(noodle.user);

                return (
                    <div key={noodle._id} id={noodle._id} className="ramen-card" >
                        <div className="card-image-container">
                            <img src={noodle.imageUrl} alt="" style={{width: 400}} />
                        </div>
                        <div className="card-text-area">
                            <h4>{noodle.title}</h4>
                            <h2>{noodle.ingredients}</h2>
                        {user ? (
                            <div className="card-button-area">
                                <div className="show-button button" onClick={() => viewNoodle(noodle._id)} >
                                    <FontAwesomeIcon icon={faEye} className="eye"/>
                                </div>
                                { owner ? (
                                    <div className="delete-update-container">
                                        <div className="update-button button" onClick={() => updateNoodle(noodle._id)} >
                                            <FontAwesomeIcon icon={faPenToSquare} className="update"/>
                                        </div>
                                        <div className="delete-button button" onClick={() => deleteNoodle(noodle._id, noodle.public_id, noodle.user)} id={noodle.id}>
                                            <FontAwesomeIcon icon={faTrash} className="delete"/>
                                        </div>
                                    </div>
                                ):(
                                    <div>
                                    </div>
                                )}
                            </div>
                                ) : (
                                    <div className="card-button-area">
                                        <div className="show-button button" onClick={() => viewNoodle(noodle._id)} >
                                        <FontAwesomeIcon icon={faEye} className="eye"/>
                                        </div>
                                    </div>
                                )}
                            </div>
                    </div>
                    );
                })}
            </div>
        </div>
    );
};

export default NoodleList;