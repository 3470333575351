import { useEffect, useState } from 'react';
import { config } from '../config/config';
import { SecondaryButton } from './buttons';
import { useNavigate } from 'react-router-dom';

interface User {
    username: string;
    imageUrl: string;
    _id: string;
    isFollowing: Boolean;
    uuid: string;
    // toggleFollow: (id: string, newState: boolean) => void;
}

const URL = config.url;

const UserCard: React.FC<User> = ({ username, imageUrl, _id, uuid, isFollowing, toggleFollow }) => {
    const navigate = useNavigate();
    // const [isFollowing, setFollowing] = useState(false);
    const [localIsFollowing, setLocalIsFollowing] = useState(isFollowing);
    
    console.log("USERCARD - isFollowing",isFollowing)

    const followUser = async (uuid: string) => { 
        console.log(uuid)
        try {
            const response = await fetch(`${URL}/follows/follow/${uuid}`, {
                method: 'POST',
                credentials: 'include'
                }
            );
        
            const data = await response.json();
            console.log(data.message);
            // if (response.ok) {
            //     // Toggle local state immediately for UI update
            //     setLocalIsFollowing((prev) => !prev);

            //     // Sync parent state through callback
            //     toggleFollow(_id, !localIsFollowing); // Notify parent
            // }
        } catch (error) {
            console.error('Error:', error);
        }
        };

    const navigateUser = async (_id: string) => {
        navigate(`/user/show/${uuid}`)
    }

    return (
        <div className="primary-card flex space-between align-vert-center">
            <div className="flex align-vert-center">
                <img src={imageUrl} alt="user-profile" className="user-profile-pic-list" />
                <p>{username}</p>
            </div>
            <div className="flex">
                { isFollowing ? (
                    <SecondaryButton 
                        buttonText="Following"
                        onClick={() =>followUser(uuid)}
                        />
                    ):(
                    <SecondaryButton 
                        buttonText="Follow"
                        onClick={() =>followUser(uuid)}
                    />      
                    )}
                <SecondaryButton 
                    buttonText="See Profile"
                    onClick={() => navigateUser(uuid)}
                />
            </div>
        </div>
    );
};

export default UserCard;