import React, { useEffect, useMemo, useState } from 'react';

import './App.css';
import './styles/main.scss';

import Navbar from './components/navbar';
import Footer from "./components/footer";
import Home from './pages/home';
import Ramen from './pages/ramen';
import Ingredient from './pages/ingredients';
import NewRamen from './pages/newRamen';
import NewIngredient from './pages/newIngredient';
import UpdateRamen from './pages/updateRamen';
import UpdateIngredient from './pages/updateIngredient';
import ShowRamen from './pages/showRamen';
import ShowIngredient from './pages/showIngredient';
import AddUser from './pages/signup';
import LoginUser from './pages/login';
import ShowUser from './pages/account';
import ShowDashi from './pages/showDashi';
import ShowTare from './pages/showTare';
import ShowOil from './pages/showOil';
import UpdateUser from './pages/updateUser';
import UserSettings from './pages/settings';
import FollowingFeed from './pages/followingFeed';
import UsersPage from './pages/users'
import Followers from './pages/followers';
import Following from './pages/following';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import ProtectedRoutes from './ProtectedRoutes';
import { User, UserContext, UserContextProps } from "./UserContext";
import { config } from './config/config';
import NewDashi from './pages/newDashi';
import NewOil from './pages/newOil';
import NewTare from './pages/newTare';
import NewNoodle from './pages/newNoodle';
import UpdateDashi from './pages/updateDashi';
import UpdateOil from './pages/updateOil';
import UpdateTare from './pages/updateTare';
import UpdateNoodle from './pages/updateNoodle';
import Tare from './pages/tare';
import Oil from './pages/oil';
import Dashi from './pages/dashi';
import Noodle from './pages/noodle';
import ShowNoodle from './pages/showNoodle';

const URL = config.url;

function App() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
      fetch(`${URL}/check-auth`, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch user data: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
            setUser(data);
          })
        .catch((err) => {
          console.error(err.message);
        });
    }, []); 

  const value: UserContextProps = useMemo(
    () => ({ user, setUser }),
    [user, setUser]
  );

  return (
    <Router>
      <UserContext.Provider value={value}>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home/>} />
              <Route path="/ramen" element={<Ramen/>} />
              <Route path="/ramen/show/:id" element={<ShowRamen/>} />
              <Route path="/dashi/show/:id" element={<ShowDashi/>} />
              <Route path="/tare/show/:id" element={<ShowTare/>} />
              <Route path="/oil/show/:id" element={<ShowOil/>} />
              <Route path="/ingredient/show/:id" element={<ShowIngredient/>} />
              <Route path="/noodle/show/:id" element={<ShowNoodle/>} />
              <Route path="/signup" element={<AddUser/>} />
              <Route path="/login" element={<LoginUser/>} />
              <Route element={<ProtectedRoutes/>}>
                <Route path="/tare" element={<Tare/>} />
                <Route path="/oil" element={<Oil/>} />
                <Route path="/dashi" element={<Dashi/>} />
                <Route path="/noodle" element={<Noodle/>} />
                <Route path="/new-ramen"  element={<NewRamen/>} />
                <Route path="/new-dashi"  element={<NewDashi/>} />
                <Route path="/new-oil"  element={<NewOil/>} />
                <Route path="/new-tare"  element={<NewTare/>} />
                <Route path="/new-noodle"  element={<NewNoodle/>} />
                <Route path="/following-feed"  element={<FollowingFeed/>} />
                <Route path="/new-ingredient" element={<NewIngredient/>  } />
                <Route path="/ingredients" element={<Ingredient/>  } />
                <Route path="/ingredient/update/:id" element={<UpdateIngredient/> }  />
                <Route path="/ramen/update/:id" element={<UpdateRamen/>  } />
                <Route path="/dashi/update/:id" element={<UpdateDashi/>  } />
                <Route path="/oil/update/:id" element={<UpdateOil/>  } />
                <Route path="/tare/update/:id" element={<UpdateTare/>  } />
                <Route path="/noodle/update/:id" element={<UpdateNoodle/>  } />
                <Route path="/user/show/:id" element={<ShowUser/>} />
                <Route path="/users" element={<UsersPage/>} />
                <Route path="/user/update/:id" element={<UpdateUser/>  } />
                <Route path="/user/settings/:id" element={<UserSettings/>  } />
                <Route path="/user/following/:id" element={<Following/>  } />
                <Route path="/user/followers/:id" element={<Followers/>  } />
              </Route>
          </Routes>
          <Footer />
        </div>
      </UserContext.Provider>
    </Router>
  );
};

export default App;