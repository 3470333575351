import { useEffect, useState } from "react";
import UserCard from "../components/userCard";
import { config } from '../config/config';
const URL = config.url;


interface Users {
    username: string;
    imageUrl: string;
    _id: string;
}

const UsersPage: React.FC<Users> = () => {
    const [users, setUsers] = useState<Users[]>([]);
    const [following, setFollowing] = useState<Users[]>([]);

    useEffect(() => {
        const fetchUsers = async () => {
            fetch(`${URL}/users/users`, {
                method: 'GET',
                credentials: 'include'
                })
            .then((response) => response.json())
            .then((data) => {
                setUsers(data);
                console.log(data)
            })
            .catch((error) =>
            console.log("Error fetching users", error)
            )};
            fetchUsers();

            const fetchFollowing = async () => {
                fetch(`${URL}/follows/following`, {
                    method: 'GET',
                    credentials: 'include'
                    })
                .then((response) => response.json())
                .then((data) => {    
                    const updatedData = data.map((user: Users) => ({ ...user, isFollowing: true }));
                    console.log("Following array",updatedData)
                    setFollowing(updatedData);
                })
                .catch((error) =>
                console.log("Error fetching users", error)
                )};
            fetchFollowing();
    }, []);

    return (
        <div className="primary-container">
            <div>
                <h1>Users</h1>
            </div>
            {users.map((user, index) => {
                const isFollowing = following.some(following => following._id === user._id);
                return <UserCard isFollowing={isFollowing} key={index} {...user} />
            })
            } 
        </div>
    )
};

export default UsersPage;

