import {Link,  useNavigate } from 'react-router-dom';
import Cookies from "universal-cookie";
import { useContext, useEffect, useState } from 'react';
import { UserContext, UserContextProps } from '../UserContext';
import { config } from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBars } from '@fortawesome/free-solid-svg-icons'
import Search from './search';

const URL = config.url;



const Navbar: React.FC = () => {
    const { user, setUser } = useContext<UserContextProps>(UserContext);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [htmlContent, setHtmlContent] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        checkWindowWidth();
        window.addEventListener('resize', checkWindowWidth);
        return () => window.removeEventListener('resize', checkWindowWidth);
    }, []);

    const logout = async () => {
        // console.log('Logout button clicked');

        try {
            const response = await fetch(`${URL}/users/logout`, {
                method: 'POST',
                credentials: 'include',
            });
        
            if (response.ok) {
                const cookies = new Cookies();
                cookies.remove("accessToken", { path: "/", domain: "localhost", secure: true });
                cookies.remove("refreshToken", { path: "/", domain: "localhost", secure: true });
                sessionStorage.clear();
                setUser(null);
                console.log('Logout successful');
                navigate('/')
            } else {
                console.error('Logout failed');
            }
            } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const navigateUser = () => {
        // console.log("navigate user triggered")
        navigate(`/user/show/${user?.userId}`)
        // WIP:
        // I dont like that I have to do this, but it works for now
        // the routing should automitcally render the new content
        // using reload is only a quick workaround
        // must fix
        window.location.reload();
    }

    const checkWindowWidth = () => {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
            console.log("true")
        } else {
            setIsMobile(false);
        }
    };

    const openMenu = () => {

        if (open) {
            setHtmlContent('');
            setOpen(false);
            document.querySelector('.mobile-menu-items')?.classList.add('hidden');
            return;
        } else {
            document.querySelector('.mobile-menu-items')?.classList.remove('hidden');
        
        setOpen(true);

        let html = `
            <ul>
                <li><Link to="/following-feed" className="item">Home</Link></li>
                <li><Link to="/ramen" className="item">Ramens</Link></li>
                <li><Link to="/ingredients" className="item">Ingredients</Link></li>
                <li><Link to="/tare" className="item">Tare</Link></li>
                <li><Link to="/dashi" className="item">Dashi</Link></li>
                <li><Link to="/oil" className="item">Oils</Link></li>
                <li><Link to="/noodle" className="item">Noodles</Link></li>
                ${user ? `
                <li><Link to="/new-ramen" className="item">Add a ramen</Link></li>
                <li><Link to="/new-ingredient" className="item">Add ingredients</Link></li>
                <li><Link to="/new-dashi" className="item">Add Dashi</Link></li>
                <li><Link to="/new-oil" className="item">Add Oil</Link></li>
                <li><Link to="/new-tare" className="item">Add Tare</Link></li>
                <li><Link to="/new-noodle" className="item">Add Noodles</Link></li>
                <li><Link to="/users" className="item">Users</Link></li>
                <li><Link to="/user/settings/${user.userId}" className="item">Settings</Link></li>
                <li><a href="/" onClick={() => logout()} className="item">Logout</a></li>
                ` : `
                <li><Link to="https://discord.gg/qWG2SRzA" className="item">Discord</Link></li>
                <li><Link to="/login" className="item">Login</Link></li>
                <li><Link to="/signup" className="item">Signup</Link></li>
                `}
            </ul>
        `;
    setHtmlContent(html);
        }
    }


    return (
        <div>
            { isMobile ? (
                <div className="mobile-navbar hidden">
                    <div>
                        Ramen Crazy
                    </div>
                    <div>
                        <div className="hamburger-menu">
                            <FontAwesomeIcon icon={faBars} onClick={openMenu} />
                        </div>
                    </div>
                    <div className="mobile-menu-items hidden" dangerouslySetInnerHTML={{ __html: htmlContent }} />
                </div>
        ):(
            <div className="navbar">
            {user  ? (
                <div className="navbar logged-in">
                    <div className="nav-left">
                        <div className='nav-logo'>
                            <Link to="/" >Ramen Crazy</Link>
                        </div>
                        <Search />
                    </div>
                    <div className="navitems"> 
                        <div className="nav-item">
                            <Link to="/following-feed" className="item">Home</Link>
                        </div>
                        <div className="nav-item">
                            <Link to="/ramen" className="item">Ramens</Link>
                        </div>
                        <div className="nav-item">
                            <Link to="/ingredients" className="item">Ingredients</Link>
                        </div>
                        <div className="nav-item">
                            <Link to="/tare" className="item">Tare</Link>
                        </div>
                        <div className="nav-item">
                            <Link to="/dashi" className="item">Dashi</Link>
                        </div>
                        <div className="nav-item">
                            <Link to="/oil" className="item">Oils</Link>
                        </div>
                        <div className="nav-item">
                            <Link to="/noodle" className="item">Noodles</Link>
                        </div>
                        <div className="user-navitem">
                            <div>
                                <FontAwesomeIcon icon={faUser} className="user-icon"/>
                                {user.userName}
                            </div>
                            <div className='nav-dropdown'>
                                <div className="dropdown-item">
                                    <Link to={`/user/show/${user?.userId}`} className="item-in-dropdown" onClick={navigateUser}>Profile</Link>
                                </div>
                                <div className="dropdown-item">
                                    <Link to="/new-ramen" className="item">Add a ramen</Link>
                                </div>
                                <div className="dropdown-item">
                                    <Link to="/new-ingredient" className="item">Add ingredients</Link>
                                </div>
                                <div className="dropdown-item">
                                    <Link to="/new-dashi" className="item">Add Dashi</Link>
                                </div>
                                <div className="dropdown-item">
                                    <Link to="/new-oil" className="item">Add Oil</Link>
                                </div>
                                <div className="dropdown-item">
                                    <Link to="/new-tare" className="item">Add Tare</Link>
                                </div>
                                <div className="dropdown-item">
                                    <Link to="/new-noodle" className="item">Add Noodles</Link>
                                </div>
                                <div className="dropdown-item">
                                    <Link to="/users" className="item">Users</Link>
                                </div>
                                <div className="dropdown-item">
                                    <Link to={`/user/settings/${user?.userId}`} className="item-in-dropdown">Settings</Link>
                                </div>
                                <div className="dropdown-item"> 
                                    <a href='/' onClick={() => logout()} className="item-in-dropdown">Logout</a>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="navitems nav-home">
                <div className="nav-item">
                    <Link to="/" className="item">Home</Link>
                </div>
                <div className="nav-item">
                    <Link to="/ramen" className="item">Ramens</Link>
                </div>
                <div className="nav-item">
                    <Link to="https://discord.gg/qWG2SRzA" className="item">Discord</Link>
                </div>
                <div className="nav-item">
                    <Link to="/login" className="item">Login</Link>
                </div>
                <div className="nav-item nav-cta">
                    <Link to="/signup" className="item">Signup</Link>
                </div>
            </div>
            )}
            </div> 
            )}
        </div>
    );
};

export default Navbar;